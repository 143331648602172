/*
 * Background on loading
 */

#fuse-splash-screen,
.login-background {
  background-color: #1a237e;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%231f2984' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23232e8b' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23283492' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%232c3998' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23303f9f' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%233a48a6' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%234350ac' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%234c59b3' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%235462b9' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%235c6bc0' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

/* FUSE Splash Screen CSS */
#fuse-splash-screen {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  pointer-events: none;
}

#fuse-splash-screen .center {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}

#fuse-splash-screen .logo {
  margin: 0 auto;
}

#fuse-splash-screen .logo-title {
  margin: 0 auto;
  display: flex;
  color: white;
  font-size: 1.4rem;
  font-weight: 300;
}

#fuse-splash-screen .logo img {
  filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))
}

// SPINNER
#fuse-splash-screen .spinner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 100px;
}

#fuse-splash-screen .spinner-wrapper .spinner {
  position: absolute;
  overflow: hidden;
  left: 50%;
  margin-left: -50px;
  animation: outer-rotate 2.91667s linear infinite;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .gap {
  position: absolute;
  left: 49px;
  right: 49px;
  top: 0;
  bottom: 0;
  border-top: 10px solid;
  box-sizing: border-box;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left,
#fuse-splash-screen .spinner-wrapper .spinner .inner .right {
  position: absolute;
  top: 0;
  height: 100px;
  width: 50px;
  overflow: hidden;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
#fuse-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  /*border              : 10px solid #F44436;     rumboservice*/
  border: 10px solid #F44436;
  /* autorecarga*/
  border-bottom-color: transparent;
  border-radius: 50%;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left {
  left: 0;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
  left: 0;
  border-right-color: transparent;
  animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .right {
  right: 0;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  right: 0;
  border-left-color: transparent;
  animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5);
  }

  100% {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes left-wobble {

  0%,
  100% {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }
}

@keyframes right-wobble {

  0%,
  100% {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }
}

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  100% {
    transform: rotate(1080deg);
  }
}

/*CUSTOM LOADING SPINNER*/
.progress-container {
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.progress {
  background-color: rgb(229, 233, 235);
  height: 0.25em;
  position: relative;
  width: 36em;
}

.progress-bar {
  background-size: 36em 0.25em;
  height: 100%;
  position: relative;
  background-image: linear-gradient(to right, rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200));
  background-image: -o-linear-gradient(to right, rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200));
  background-image: -ms-linear-gradient(to right, rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200));
  background-image: -webkit-linear-gradient(to right, rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200));
  background-image: -moz-linear-gradient(to right, rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200), rgb(0, 107, 200));
  animation: cssload-width 3s cubic-bezier(0.45, 0, 1, 1) infinite;
  -o-animation: cssload-width 3s cubic-bezier(0.45, 0, 1, 1) infinite;
  -ms-animation: cssload-width 3s cubic-bezier(0.45, 0, 1, 1) infinite;
  -webkit-animation: cssload-width 3s cubic-bezier(0.45, 0, 1, 1) infinite;
  -moz-animation: cssload-width 3s cubic-bezier(0.45, 0, 1, 1) infinite;
}

.progress-shadow {
  height: 6em;
  position: absolute;
  top: 100%;
  width: 100%;
  transform: skew(45deg);
  -o-transform: skew(45deg);
  -ms-transform: skew(45deg);
  -webkit-transform: skew(45deg);
  -moz-transform: skew(45deg);
  transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  background-image: linear-gradient(to bottom, rgb(234, 236, 238), transparent);
  background-image: -o-linear-gradient(to bottom, rgb(234, 236, 238), transparent);
  background-image: -ms-linear-gradient(to bottom, rgb(234, 236, 238), transparent);
  background-image: -webkit-linear-gradient(to bottom, rgb(234, 236, 238), transparent);
  background-image: -moz-linear-gradient(to bottom, rgb(234, 236, 238), transparent);
}


@keyframes cssload-width {
  0%, 100% {
    transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-o-keyframes cssload-width {
  0%, 100% {
    -o-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-ms-keyframes cssload-width {
  0%, 100% {
    -ms-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes cssload-width {
  0%, 100% {
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-moz-keyframes cssload-width {
  0%, 100% {
    -moz-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* / FUSE Splash Screen CSS */
