@use '@angular/material' as mat;

@import "../mixins/mixing";

@include mat.core();

$md-primary: (
  50 : #e2e9f4,
  100 : #b6c8e3,
  200 : #86a3d0,
  300 : #567ebd,
  400 : #3163af,
  500 : #0d47a1,
  600 : #0b4099,
  700 : #09378f,
  800 : #072f85,
  900 : #032074,
  A100 : #a2b4ff,
  A200 : #6f8aff,
  A400 : #3c61ff,
  A700 : #234cff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$md-accent: (
  50 : #fee8e7,
  100 : #fcc7c3,
  200 : #faa19b,
  300 : #f77b72,
  400 : #f65f54,
  500 : #f44336,
  600 : #f33d30,
  700 : #f13429,
  800 : #ef2c22,
  900 : #ec1e16,
  A100 : #ffffff,
  A200 : #ffe9e9,
  A400 : #ffb8b6,
  A700 : #ff9f9c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$main-color: mat.get-color-from-palette($md-primary, 500);
$secondary-color: mat.get-color-from-palette(mat.$grey-palette, 500, 300);

$app-light-primary: mat.define-palette($md-primary, 500);
$app-light-accent: mat.define-palette($md-accent, 500);
$app-light-warn: mat.define-palette(mat.$red-palette, 500);

$custom-typography: mat.define-typography-config(
  $font-family: 'Roboto, sans-serif',
);

$app-light-theme: mat.define-light-theme((
  color: (
    primary: $app-light-primary,
    accent: $app-light-accent,
    warn: $app-light-warn,
  ),
  typography: $custom-typography
));

@include mat.all-component-themes($app-light-theme);

@include mixin-theme($app-light-theme);

.timepicker__header {
  background-color: $main-color !important;
}

.timepicker-button {
  color: $main-color !important;
}

.mat-tab-label-active {
  color: $main-color !important;
  font-weight: 500 !important;
}

td.active {
  background-color: $main-color !important;
}

td {
  .active {
    background-color: $main-color !important;
  }
}

.primary-color {
  color: $main-color;
}

.btn {
  margin-left: 10px;
  background-color: $main-color !important;
}

.md-drppicker .ranges ul li button.active {
  background-color: $main-color !important;
  color: #fff;
}

.date-range .btn {
  background-color: $main-color !important;
}

.date-range .md-drppicker .btn {
  background-color: $main-color !important;
}

.md-drppicker .btn:focus,
.md-drppicker .btn:hover {
  background-color: $main-color !important;
}

.buttons .buttons_input .btn {
  background-color: $main-color !important;
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: $main-color;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: $main-color !important;
}

.contract-input:focus {
  border: $main-color solid 2.2px !important;
  box-shadow: 0 0 10px $main-color !important;
}

.contract-input-error:focus {
  border: #f44336 solid 2.5px !important;
  // outline: none !important;
  box-shadow: 0 0 10px #f44336 !important;
}

.ngx-slider .ngx-slider-pointer {
  background-color: $main-color !important;
  width: 13px !important;
  height: 13px !important;
  top: -6px !important;
  background-color: $main-color;
  z-index: 3 !important;
  border-radius: 16px !important;
}

.ngx-slider .ngx-slider-selection {
  background: $main-color !important;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: $main-color !important;
}

.ngx-slider .ngx-slider-pointer:after {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 4px;
  background-color: $main-color !important;
}
