@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat.core();

$requested-color: mat.get-color-from-palette(mat.$amber-palette, 600); // Amber600
$pending-color: mat.get-color-from-palette(mat.$amber-palette, 600); // Amber600
$confirmed-color: mat.get-color-from-palette(mat.$blue-palette, 500); // Blue500;
$processing-payment-color: mat.get-color-from-palette(mat.$green-palette, 200); // Green200;
$payed-color: mat.get-color-from-palette(mat.$green-palette, 700); // Green700;
$processing-refund-color: mat.get-color-from-palette(mat.$brown-palette, 200); // Brown200;
$refunded-color: mat.get-color-from-palette(mat.$brown-palette, 500); // Brown500;
$cancelled-color: mat.get-color-from-palette(mat.$red-palette, 500); // Red500;
$processing-cancelled-color: mat.get-color-from-palette(mat.$red-palette, 200); // Red200;
$expired-color: mat.get-color-from-palette(mat.$grey-palette, 700); // Gray700;
$consumed-color: mat.get-color-from-palette(mat.$teal-palette, 700); // Teal700;
$modified-color: mat.get-color-from-palette(mat.$blue-grey-palette, 500); // BlueGray500;
$processing-modification-color: mat.get-color-from-palette(mat.$blue-grey-palette, 500); // BlueGray200;
$execution-color: mat.get-color-from-palette(mat.$blue-palette, 700); // Teal600;

//
/*
 * Color del texto, clases usadas para darle color al texto
 */
.requested-text {
  color: $requested-color;
}

.confirmed-text {
  color: $confirmed-color;
}

.processingPayment-text {
  color: $processing-payment-color;
}

.payed-text {
  color: $payed-color;
}

.processingRefund-text {
  color: $processing-refund-color;
}

.refunded-text {
  color: $refunded-color;
}

.cancelled-text {
  color: $cancelled-color;
}

.expired-text {
  color: $expired-color;
}

.consumed-text {
  color: $consumed-color;
}

.modified-text {
  color: $modified-color;
}

.processingModification-text {
  color: $processing-modification-color;
}

//
/*
 * Color del fondo, clases usadas para darle color al fondo
 */
.requested-tag {
  background-color: $requested-color;
}

.confirmed-tag {
  background-color: $confirmed-color;
}

.processingPayment-tag {
  background-color: $processing-payment-color;
}

.payed-tag {
  background-color: $payed-color;
}

.processingRefund-tag {
  background-color: $processing-refund-color;
}

.refunded-tag {
  background-color: $refunded-color;
}

.cancelled-tag {
  background-color: $cancelled-color;
}

.expired-tag {
  background-color: $expired-color;
}

.consumed-tag {
  background-color: $consumed-color;
}

.modified-tag {
  background-color: $modified-color;
}

.processingModification-tag {
  background-color: $processing-modification-color;
}

.requested {
  background-color: $requested-color;
}

.confirmed {
  background-color: $confirmed-color;
}

.processing-payment {
  background-color: $processing-payment-color;
}

.payed {
  background-color: $payed-color;
}

.processing-refund {
  background-color: $processing-refund-color;
}

.refunded {
  background-color: $refunded-color;
}

.cancelled {
  background-color: $cancelled-color;
}

.expired {
  background-color: $expired-color;
}

.consumed {
  background-color: $consumed-color;
}

.modified {
  background-color: $modified-color;
}

.execution {
  background-color: $execution-color;
}

//
/*
 * Padding establecido para hacer
 */
.tag-padding {
  padding: 4px 10px;
  border-radius: 5px;
}

//
/*
 * Color del texto blanco, para usar con las clases de color de fondo
 */
.text-white {
  color: white;
}
