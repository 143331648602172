/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/purple-green.css';
@import '../node_modules/swiper/swiper.min.css';
@import './assets/styles/theme/theme';
@import './assets/styles/variable';
@import './assets/styles/helpers/helpers';
@import './assets/styles/pallete-colors';
@import './assets/styles/pallete-backgrounds';
@import './assets/styles/reservation_status';

// regular style toast
@import 'ngx-toastr/toastr.css';
@import 'ng-pick-datetime/assets/style/picker.min.css';

@import 'assets/styles/splash-screen';

html,
body {
  box-sizing: border-box;
  padding: 0 !important;
  margin: 0 !important;
  font-family: 'Roboto', sans-serif;
  position: relative;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  scrollbar-width: thin !important;
}

.ship-category {
  padding: 1px 11px;
  border-radius: 24px;
  background-color: #e3f2fc;
  color: black;
  font-size: 12px;
}

:root {
  scrollbar-width: thin !important;
  --reserve-color: #1976d2;
  --execution-color: #09d822;
  --consumed-color: #607d8b;
  --pending-color: #f11c1c;

  --repair-color: #572364;
  --review-color: #f7d641;
  --mandatory-repair-color: #8d0f67;
  --courtesy-color: #ffa000;
}

a {
  text-decoration: none;
}

//.mat-tab-body-content {
//  scrollbar-width: thin !important;
//}

.height-100 {
  height: 100% !important;
}

.width-100 {
  width: 100% !important;
}

.my-stroked-button {
  border: solid 2px $grey-600 !important;

  span {
    color: $grey-600 !important;
    text-transform: uppercase !important;
  }
}

app-mat-select-with-filter {
  .mat-filter {
    background-color: white !important;
  }
}

.executed {
  background-color: forestgreen;
  color: white;
}

.repairing {
  background-color: #ffca28;
  color: black;
}

.mat-tab-label-container {
  background-color: white !important;
}

.StrokedBTNActions {
  color: $grey-700 !important;
  height: 34px !important;
  width: 34px !important;
  min-width: 34px !important;
  padding: 1px !important;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

.mat-expansion-panel-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fixed {
  .mat-tab-header {
    position: fixed;
    top: 64px;
    z-index: 10;
    width: 100%;
  }
}

.tab-group-full-height {
  .mat-tab-body-wrapper {
    height: 100% !important;
  }
}

mat-toolbar {
  .logo {
    width: 48px;
    height: 48px;
  }
}

mat-sidenav {
  width: 20vw;

  @media (1440 > width > 1280px) {
    width: 25vw;
  }

  @media (width < 1280px) {
    width: 70vw;
  }
}

@media only screen and (max-width: 480px) {
  mat-sidenav-content {
    margin-left: 0 !important;
  }

  .hideXs {
    display: none;
    background-color: red;
  }

  td.mat-cell:first-child,
  td.mat-footer-cell:first-child,
  th.mat-header-cell:first-child {
    padding-left: 14px !important;
  }

  td.mat-cell:last-child,
  td.mat-footer-cell:last-child,
  th.mat-header-cell:last-child {
    padding-right: 14px !important;
  }

  .fixed {
    .mat-tab-header {
      position: fixed;
      top: 56px;
      z-index: 10;
      width: 100%;
    }
  }
}

.btn.btn-default {
  background-color: $grey-100 !important;
}

.input-warning {
  position: absolute;
  color: $amber-700;
  margin-top: 65px;
}

.responsive-table {
  overflow-x: auto !important;
  scrollbar-width: thin !important;
}

@media (max-width: 800px) {
  .responsive-table {
    overflow-x: auto !important;
    scrollbar-width: thin !important;
  }

  .mat-table {
    min-width: 800px;
  }
}

.mat-flat-button {
  border-radius: 4px !important;
}

.mat-flat-button {
  border-radius: 4px !important;
}

.mat-stroked-button {
  border-radius: 4px !important;
}

.mat-raised-button {
  border-radius: 4px !important;
}

.border-content {
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0);
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 24px rgba(0, 0, 0, 0.37);
  border-radius: 24px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 24px rgba(0, 0, 0, 0.54);
  border-radius: 24px;
}

.loading-shade-full {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.48) !important;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

button:focus {
  outline: none !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.iconHelp {
  font-size: 14px;
  color: $grey-600;
  width: 14px;
  height: 14px;
}

button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

.loader {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  -webkit-animation: 2s ease-in-out infinite progress;
  animation: 2s ease-in-out infinite progress;
  background: #eff1f6 linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0)) no-repeat 0 0;
  width: 100%;
  height: 20px;
  display: inline-block;
}

@-webkit-keyframes progress {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

@keyframes progress {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.search-input {
  font-size: 20px;
  outline: none;
  border: 0;
  box-shadow: none;
  background-color: #fcfcfc;
  height: 100%;
  border-radius: 8px;
  padding: 8px;
}

guachos-data-table {
  .mat-sort-header-content,
  .mat-header-cell {
    span {
      font-size: 12px;
      font-weight: 400 !important;
      line-height: 16px;
      letter-spacing: 1.5px;
      text-align: left;
    }
  }

  .mat-cell {
    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-align: left;
    }
  }
}

.availability-filters-selector-mat-select {
  .mat-option-text {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    white-space: break-spaces !important;
    line-height: 1.3 !important;
  }

  .mat-option {
    padding: 0.5rem 1rem;
    line-height: 3.5em !important;
    height: auto !important;
    max-height: 5rem !important;
  }

  .color-red {
    color: red
  }

  .color-green {
    color: green;
  }
}
