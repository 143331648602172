@use '@angular/material' as mat;

@mixin layout-theme($theme) {
  $config: mat.get-color-config($theme);
  $primary: map-get($config, primary);

  app-layout {
    .sidenav {
      background-color: mat.get-color-from-palette($primary, 500) !important;

      mat-nav-list {
        a {
          color: #ffffff !important;

          &:hover {
            background-color: #e8eaf6 !important;

            span {
              color: mat.get-color-from-palette($primary, 500) !important;
              font-weight: 500 !important;
            }

            .mat-icon {
              color: mat.get-color-from-palette($primary, 500) !important;
            }
          }
        }

        .mat-expansion-panel {
          background-color: mat.get-color-from-palette($primary, 500) !important;
        }

        .mat-expansion-panel-header {
          background-color: mat.get-color-from-palette($primary, 500) !important;

          &:hover {
            background-color: #e8eaf6 !important;

            span {
              color: mat.get-color-from-palette($primary, 500) !important;
              font-weight: 500 !important;
            }

            .mat-icon {
              color: mat.get-color-from-palette($primary, 500) !important;
            }
          }

          span, mat-icon {
            color: #ffffff !important;
          }

          .mat-expansion-indicator::after {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}
